import React, { useState, useContext, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContext } from '../../../context/toast';
import { WalletContext } from '../../../context/wallet';

import { getLpAddress } from '../../../utils/commons';

import { approveFarm } from '../../../blockchain/farms';

const FarmStakeSection = ({ farmData, handleWithdrawModalOpen, handleDepositModalOpen, nativeTokenPriceDefault }) => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);
  const [pendingTx, setPendingTx] = useState(false);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const ss = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setStarted(prevState => prevState || (ss.gt(0) && ss.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [setStarted]);

  const farmStakedBalance = () => {
    const decimals = farmData.isTokenOnly ? farmData.tokenDecimals : Number(process.env.REACT_APP_DECIMALS);

    const farmStakedValue = new BigNumber(farmData.stakedBalance)
      .div(new BigNumber(10).pow(decimals));

    return farmStakedValue.toFormat(farmStakedValue.eq(0) || farmStakedValue.gt(1) ? 2 : decimals);
  }

  const hasStakedBalance = () => new BigNumber(farmData.stakedBalance).gt(0);

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveFarm(farmData.isTokenOnly ? farmData.token : getLpAddress(farmData.token, farmData.quoteToken));
      await tx.wait();
      addToast(`${farmData.isTokenOnly ? '' : 'LP '}Token Approve succeeded`, 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast(`${farmData.isTokenOnly ? '' : 'LP '}Token Approve failed`, 'is-danger');
    }

    setPendingTx(false);
  }

  const renderUnlockApproveOrStake = () => {
    if (walletAddress === null) {
      return (
        <div className="level-item">
          <button className="button is-primary" onClick={ handleConnectClick }>
            Unlock
          </button>
        </div>
      );
    }

    if (new BigNumber(farmData.userAllowance).eq(0)) {
      return (
        <div className="level-item">
          <button
            disabled={ pendingTx || !started || (nativeTokenPriceDefault && farmData.isNative) }
            className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
            onClick={ handleApprove }
          >
            Approve
          </button>
        </div>
      );
    }

    if (hasStakedBalance()) {
      return (
        <>
          <div className="level-item">
            <button
              className="button is-primary is-outlined"
              onClick={ () => handleWithdrawModalOpen(farmData) }
            >
              <span className="icon">
                <FontAwesomeIcon icon={ faMinus } />
              </span>
            </button>
          </div>
          <div className="level-item">
            <button
              disabled={ !started }
              className="button is-primary is-outlined"
              onClick={ () => handleDepositModalOpen(farmData) }
            >
              <span className="icon">
                <FontAwesomeIcon icon={ faPlus } />
              </span>
            </button>
          </div>
        </>
      );
    }

    return (
      <div className="level-item">
        <button
          disabled={ !started }
          className="button is-primary"
          onClick={ () => handleDepositModalOpen(farmData) }
        >
          STAKE
        </button>
      </div>
    );
  }

  return (
    <>
      <small>STAKED <span className="has-text-primary">{ farmData.name }</span></small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h5 className="is-size-5 has-text-weight-semibold">
              { farmStakedBalance() }
            </h5>
          </div>
        </div>
        <div className="level-right">
          { renderUnlockApproveOrStake() }
        </div>
      </div>
    </>
  );
}

export default FarmStakeSection;
