const farmInitialState = {
  loading: false,
  firstLoad: true,
  nativeTokenPrice: 0,
  nativeTokenPriceDefault: false,
  tvl: 0,
  tnl: 0,
  maxFarmApr: 0,
  tokenTotalSupply: 0,
  tokenTotalBurned: 0,
  tokenPerSecond: 0,
  tokenMaximumSupply: 0,
  pendingUsd: 0,
  farms: [
    // FARMS
    {
      pid: 4,
      token: 'banksy',
      quoteToken: 'wwdoge',
      isTokenOnly: false,
      name: 'BANKSY-WWDOGE LP',
      swap: 'YodeSwap',
    },
    {
      pid: 1,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'BANKSY-USDC LP',
      swap: 'YodeSwap',
    },
    {
      pid: 5,
      token: 'wwdoge',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'USDC-FTM LP',
      swap: 'YodeSwap',
    },
    {
      pid: 6,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'USDC-fUSDT LP',
      swap: 'YodeSwap',
    },
    {
      pid: 7,
      token: 'eth',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'ETH-USDC LP',
      swap: 'YodeSwap',
    },
    // {
    //   pid: 5,
    //   token: 'usdt',
    //   quoteToken: 'wwdoge',
    //   isTokenOnly: false,
    //   name: 'fUSDT-FTM LP',
    //   swap: 'YodeSwap',
    // },
    // POOLS
    {
      pid: 0,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'YodeSwap',
    },
    {
      pid: 8,
      token: 'wwdoge',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'WWDOGE',
      swap: 'YodeSwap',
    },
    {
      pid: 11,
      token: 'usdc',
      quoteToken: 'wwdoge',
      isTokenOnly: true,
      name: 'USDC',
      swap: 'YodeSwap',
    },
    {
      pid: 9,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'USDT',
      swap: 'YodeSwap',
    },
    // {
    //   pid: 10,
    //   token: 'dai',
    //   quoteToken: 'wwdoge',
    //   isTokenOnly: true,
    //   name: 'DAI',
    //   swap: 'YodeSwap',
    // },
    {
      pid: 12,
      token: 'eth',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'ETH',
      swap: 'YodeSwap',
    },
    // {
    //   pid: 9,
    //   token: 'btc',
    //   quoteToken: 'wwdoge',
    //   isTokenOnly: true,
    //   name: 'BTC',
    //   swap: 'YodeSwap',
    // },
  ],
}

export default farmInitialState;
