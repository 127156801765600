import React from 'react';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';

import { nftImage, nftName } from '../../../utils/nft';

const NftCard = ({ nftData, hideDetails = false, ribbon = '', ribbonLink = '#' }) => {
  return (
    <div className={ `box p-3 is-relative ${nftData.mergeCount > 0 ? 'gradient-border' : ''}` }>
      { ribbon !== '' ? (
        <Link to={ ribbonLink } className="ribbon ribbon-top-right">
          <span>{ ribbon }</span>
        </Link>
      ) : null }
      { nftImage(nftData) }
      <p className="title mb-1 is-flex">
        { nftName(nftData) }
      </p>
      {hideDetails ? null : (
        <div className="column is-narrow mb-3">
          <div className="field is-grouped is-grouped-multiline">
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Generation</span>
                <span className="tag mb-0 is-info">{ nftData.generation }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Experience</span>
                <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).toFormat(0) }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Power</span>
                <span className="tag mb-0 is-primary">{ nftData.power }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Boost Stake</span>
                <span className="tag mb-0 is-danger">{ new BigNumber(nftData.boostStake).toFormat(2) }%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NftCard;
