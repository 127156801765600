import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import nftChefAbi from '../config/abis/nftChef.json';
import erc20Abi from '../config/abis/erc20.json';
import nftSaleAbi from '../config/abis/nftSale.json';
import nftAirdropAbi from '../config/abis/nftAirdrop.json';
import nftMergeAbi from '../config/abis/nftMerge.json';
import { getAddress } from '../utils/commons';
import { getContract, getSignedContract, getWalletAddress } from './commons';
import { experienceScale } from '../utils/nft';

const ZERO = new BigNumber(0);

export const getNfts = async (pids) => {
  if (pids.length === 0) {
    return [];
  }

  const nftChefAddress = getAddress('nftChef');

  let nftCalls = [];

  const nfts = [];
  const nftRequests = [];

  for (let index = 0; index < pids.length; index++) {
    const pid = new BigNumber(pids[index]).toString();

    nftCalls.push({
      address: nftChefAddress,
      name: 'artWorks',
      params: [pid],
    });

    nftCalls.push({
      address: nftChefAddress,
      name: 'getApproved',
      params: [pid],
    });

    if ((index === pids.length - 1) || index % 300 === 0) {
      nftRequests.push(multicall(nftChefAbi, nftCalls));

      nftCalls = [];
    }
  }

  const nftResultsArr = await Promise.all(nftRequests);

  const nftResults = nftResultsArr.flat();

  for (let j = 0; j < pids.length; j++) {
    const i = j === 0 ? j : j * 2;

    const pid = new BigNumber(pids[j]).toString();

    const power = new BigNumber(nftResults[i][0]._hex);
    const experience = new BigNumber(nftResults[i][1]._hex).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));
    const generation = new BigNumber(nftResults[i][2]._hex);
    const mergeCount = new BigNumber(nftResults[i][3]._hex);

    const boostStake = (power.times(5).plus(experienceScale(experience))).div(100);

    nfts.push({
      allowance: nftResults[i + 1][0],
      power: power.toNumber(),
      boostStake: boostStake.toJSON(),
      experience: experience.toJSON(),
      generation: generation.toNumber(),
      mergeCount: mergeCount.toNumber(),
      pid
    });
  }

  return nfts;
}

export const fetchMyNfts = async () => {
  const walletAddress = await getWalletAddress();

  if(walletAddress === null) {
    return {
      myNfts: [],
      firstLoad: false,
    };
  }

  const nftPidsCalls = [];

  const nftChefAddress = getAddress('nftChef');
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const userBalanceRaw = await nftChefContract.balanceOf(walletAddress);
  const userBalance = new BigNumber(userBalanceRaw._hex);

  for (let i = 0; userBalance.gt(i); i++) {
    nftPidsCalls.push({
      address: nftChefAddress,
      name: 'tokenOfOwnerByIndex',
      params: [walletAddress, i],
    });
  }
  const nftPidsResults = await multicall(nftChefAbi, nftPidsCalls);

  const myNfts = await getNfts(nftPidsResults);

  return {
    myNfts,
    firstLoad: false
  };
}

export const fetchNftMerge = async () => {
  const walletAddress = await getWalletAddress();

  if (walletAddress === null) {
    return {
      nfts: [],
      firstLoad: false,
      pendingClaim: 999999,
      pending: false,
    };
  }

  const nftPidsCalls = [];

  const nftChefAddress = getAddress('nftChef');
  const nftMergeAddress = getAddress('nftMerge');
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const userBalanceRaw = await nftChefContract.balanceOf(walletAddress);
  const userBalance = new BigNumber(userBalanceRaw._hex);

  const nftMergeCalls = [
    {
      address: nftMergeAddress,
      name: 'pendingClaim',
      params: [walletAddress],
    },
    {
      address: nftMergeAddress,
      name: 'pendingProcess',
      params: [walletAddress],
    },
  ];

  const nftMergeResults = await multicall(nftMergeAbi, nftMergeCalls);

  let step = nftMergeResults[1][0] ? 2 : 1;

  for (let i = 0; userBalance.gt(i); i++) {
    nftPidsCalls.push({
      address: nftChefAddress,
      name: 'tokenOfOwnerByIndex',
      params: [walletAddress, i],
    });
  }

  const nftPidsResults = await multicall(nftChefAbi, nftPidsCalls);

  const pendingClaim = new BigNumber(nftMergeResults[0]);

  if (!pendingClaim.eq(999999)) {
    nftPidsResults.push(pendingClaim);
    step = 3;
  }

  const nfts = await getNfts(nftPidsResults);

  let nftClaim = {};
  if (step === 3) {
    nftClaim = nfts.pop();
  }

  return {
    nfts,
    firstLoad: false,
    pendingClaim: pendingClaim.toJSON(),
    nftClaim,
    pendingProcess: nftMergeResults[1][0],
    step
  };
}

export const fetchNftRanking = async () => {
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const totalBalanceRaw = await nftChefContract.totalSupply();
  const totalBalance = new BigNumber(totalBalanceRaw._hex);

  let items = await getNfts([...Array(totalBalance.toNumber()).keys()]);

  items = items.sort((a, b) => {
    if (new BigNumber(a.experience).gt(b.experience)) {
      return -1;
    }

    if (new BigNumber(a.experience).lt(b.experience)) {
      return 1;
    }

    return 0;
  }).slice(0, 20);

  return { items };
}

export const fetchNftSale = async () => {
  const walletAddress = await getWalletAddress();

  const nftChefAddress = getAddress('nftChef');
  const tokenAddress = getAddress('usdc');
  let nftChefCalls = [];
  let erc20Calls = [];
  let nftSaleCalls = [];

  for (let i = 0; i < 1; i++) {
    const nftSaleAddress = getAddress(`nftSale${i}`);
    nftChefCalls.push({
      address: nftChefAddress,
      name: 'balanceOf',
      params: [nftSaleAddress],
    });

    if (walletAddress !== null) {  
      erc20Calls.push({
        address: tokenAddress,
        name: 'allowance',
        params: [
          walletAddress,
          nftSaleAddress,
        ],
      });
    }

    nftSaleCalls.push({
      address: nftSaleAddress,
      name: 'startTime',
    });

    nftSaleCalls.push({
      address: nftSaleAddress,
      name: 'salePrice',
    });

    nftSaleCalls.push({
      address: nftSaleAddress,
      name: 'nftCardPerAccountMaxTotal',
    });

    if (walletAddress !== null) {
      nftSaleCalls.push({
        address: nftSaleAddress,
        name: 'userNftCardTotally',
        params: [walletAddress],
      });
    }
  }

  if (walletAddress !== null) {
    nftChefCalls.push({
      address: nftChefAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });

    erc20Calls.push({
      address: tokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
  }

  erc20Calls.push({
    address: tokenAddress,
    name: 'decimals',
  });

  const nftChefResults = await multicall(nftChefAbi, nftChefCalls);
  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const nftSaleResults = await multicall(nftSaleAbi, nftSaleCalls);

  let current = 0;
  while (current < 3) {
    if (new BigNumber(nftChefResults[current]).gt(0)) {
      break;
    }
    current += 1;
  }
  const offset = (walletAddress !== null ? 4 : 3) * current;

  return {
    current,
    nftCardsRemaining: new BigNumber(nftChefResults[current]).toJSON(),
    userNftBalance: walletAddress !== null ? new BigNumber(nftChefResults[nftChefResults.length - 1]).toJSON() : ZERO.toJSON(),
    tokenDecimals: erc20Results[erc20Results.length - 1],
    userBalance: walletAddress !== null ? new BigNumber(erc20Results[erc20Results.length - 2]).toJSON() : ZERO.toJSON(),
    userAllowance: walletAddress !== null ? new BigNumber(erc20Results[current]).toJSON() : ZERO.toJSON(),
    startTime: new BigNumber(nftSaleResults[offset + 0]).toJSON(),
    salePrice: new BigNumber(nftSaleResults[offset + 1]).toJSON(),
    nftCardPerAccountMaxTotal: new BigNumber(nftSaleResults[offset + 2]).toJSON(),
    userNftCardTotally: walletAddress !== null ? new BigNumber(nftSaleResults[offset + 3]).toJSON() : ZERO.toJSON(),
    firstLoad: false,
  };
}

export const fetchNftAirdrop = async () => {
  const walletAddress = await getWalletAddress();

  const nftAirdropAddress = getAddress('nftAirdrop');

  const nftAirdropCalls = [
    {
      address: nftAirdropAddress,
      name: 'startTime',
    },
  ];

  if (walletAddress !== null) {
    nftAirdropCalls.push({
      address: nftAirdropAddress,
      name: 'recipients',
      params: [walletAddress],
    });
  }

  const nftAirdropResults = await multicall(nftAirdropAbi, nftAirdropCalls);

  return {
    startTime: new BigNumber(nftAirdropResults[0] || 0).toJSON(),
    userCanClaim: nftAirdropResults[1] ? nftAirdropResults[1][0] : false,
  }
}

export const claimNftAirdrop = async () => {
  const nftAirdropContract = await getSignedContract('nftAirdrop', nftAirdropAbi);
  return await nftAirdropContract.claimBanksyAirdrop();
}

export const approveNftSale = async (token, index) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress(`nftSale${index}`), ethers.constants.MaxUint256);
}

export const buyNftCard = async (amount, index) => {
  const nftSaleContract = await getSignedContract(`nftSale${index}`, nftSaleAbi);
  return await nftSaleContract.buyNftCard(amount);
}

export const mergeNftArtWork = async (pids) => {
  const nftMergeContract = await getSignedContract('nftMerge', nftMergeAbi);
  return await nftMergeContract.mergeNftArtWork(pids.map(pid => Number(pid)));
}

export const claimNftMerged = async () => {
  const nftMergeContract = await getSignedContract('nftMerge', nftMergeAbi);
  return await nftMergeContract.claimNftMerged();
}

export const approveNft = async (address, pid) => {
  const nftChefContract = await getSignedContract('nftChef', nftChefAbi);
  return await nftChefContract.approve(address, pid);
}
