const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0xA8b01bc8b06DBaAE87511717D3fd37e6AbEf1b58');
contractsMainNet.set('treasuryDAO', '0x7700ba185C7ccCCD9a6c08724D0caBeA80e9A415');
contractsMainNet.set('treasuryUtil', '0xCDD4D0C5EE1DaDe3d9FdC6470C40FC401e6239E2');
contractsMainNet.set('liquidityToolBox', '0x0E6331E1C18712a789fC7436Ce683f99e6080355');
contractsMainNet.set('multiCall', '0x114f5B67aF2d7029F2dFbe6A119DFC6B4D7A5519');

contractsMainNet.set('nftChef', '0x13ba3664d0fe094bAFca5625D968e6B4FF22Aaa9');
contractsMainNet.set('nftSale0', '0x6AD400Cf87fd197A9b650594Faae456F3f4B0399');
contractsMainNet.set('nftAirdrop', '0x4a8c6aa751EbF9469264cDf22Bf30Cba9d57a3d5');

// single tokens
contractsMainNet.set('banksy', '0x0FFC2679f282c430C42731a3821C1eDFf1983981');
contractsMainNet.set('wwdoge', '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101');
contractsMainNet.set('eth', '0xB44a9B6905aF7c801311e8F4E76932ee959c663C');
contractsMainNet.set('btc', '0xfa9343c3897324496a05fc75abed6bac29f8a40f');
// contractsMainNet.set('dai', '');
contractsMainNet.set('usdc', '0x765277EebeCA2e31912C9946eAe1021199B39C61');
contractsMainNet.set('usdt', '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D');
// LPs
contractsMainNet.set('banksy-wwdoge', '0x9d9e4181d6b3749453c3f770617d34f0EEEeb309');
contractsMainNet.set('banksy-usdc', '0x01Dd190f168bCC0F0822C3277F6107B92dd9Ad3b');
contractsMainNet.set('wwdoge-usdc', '0x8DCeBE9f071562D52b5ABB17235f3bCA768C1e44');
// contractsMainNet.set('dai-wwdoge', '');
// contractsMainNet.set('usdt-wwdoge', '');
contractsMainNet.set('eth-usdc', '0x7a3542fADA818C705E1Ce6dA508F5aa5dfC35edd');
// contractsMainNet.set('btc-wwdoge', '');
contractsMainNet.set('usdt-usdc', '0xD95b66FBD36C5754FE7C837500cccBf113fD6ac6');

// Fake
// contractsMainNet.set('fdai-wwdoge', '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428');
// contractsMainNet.set('fdai', '0x3573E1C88D66073F1d5d497a12EB7eb796a244CE');
// contractsMainNet.set('fusdc', '0x3064EDEE4ADA222806db307b90A0b7A36B4cbd18');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-wwdoge', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
