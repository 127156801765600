import BigNumber from 'bignumber.js';
import multicall from './multicall';
import masterChefAbi from '../config/abis/masterchef.json';
import nftSaleAbi from '../config/abis/nftSale.json';
import nftAirdropAbi from '../config/abis/nftAirdrop.json';
import nftChefAbi from '../config/abis/nftChef.json';
import { getAddress } from '../utils/commons';

export const fetchTimeline = async () => {
  const masterChefAddress = getAddress('masterChef');
  const nftAirdropAddress = getAddress('nftAirdrop');
  const nftChefAddress = getAddress('nftChef');

  const masterChefCalls = [
    {
      address: masterChefAddress,
      name: 'startTime',
    }
  ];

  const nftSaleCalls = [];
  const nftChefCalls = [];

  for(let i = 0; i < 1; i++) {
    nftSaleCalls.push({
      address: getAddress(`nftSale${i}`),
      name: 'startTime',
    });
    nftChefCalls.push({
      address: nftChefAddress,
      name: 'balanceOf',
      params: [getAddress(`nftSale${i}`)],
    });
  }

  nftChefCalls.push({
    address: nftChefAddress,
    name: 'balanceOf',
    params: [nftAirdropAddress],
  });

  const masterChefResults = await multicall(masterChefAbi, masterChefCalls);
  const nftSaleResults = await multicall(nftSaleAbi, nftSaleCalls);
  const nftChefResults = await multicall(nftChefAbi, nftChefCalls);
  const nftAirdropResults = await multicall(nftAirdropAbi, [{
    address: nftAirdropAddress,
    name: 'startTime',
  }]);

  let nftSaleCurrent = 0;
  while (nftSaleCurrent < 1) {
    if (new BigNumber(nftChefResults[nftSaleCurrent]).gt(0)) {
      break;
    }
    nftSaleCurrent += 1;
  }

  return {
    firstLoad: false,

    masterChefStartTime: new BigNumber(masterChefResults[0] || 0).toJSON(),

    nftSale0StartTime: new BigNumber(nftSaleResults[0] || 0).toJSON(),
    nftSale0BalanceOf: new BigNumber(nftChefResults[0] || 0).toJSON(),
    nftSaleCurrent,

    nftAirdropStartTime: new BigNumber(nftAirdropResults[0] || 0).toJSON(),
    nftAirdropBalanceOf: new BigNumber(nftChefResults[nftChefResults.length - 1] || 0).toJSON(),
  };
}
