import { useRef } from 'react';

const genName = (token) => {
  if (token.generation === 1) {
    return 'The DeFi Boy'
  }

  return 'Morons';
}

export const nftName = (token) => `${genName(token)} #${token.pid}`

const Model = () => {
  const modelRef = useRef();

  return (
    <figure className="image is-16by9 mb-3">
      <model-viewer
        src="/nfts/3.glb"
        alt="A rock"
        camera-controls
        ar
        ar-modes="webxr"
        ref={(ref) => {
          modelRef.current = ref;
        }}
      >
      </model-viewer>
    </figure>
  );
}

export const nftImage = (token) => {
  return (
    <Model />
  );
  // if (token.generation === 1) {
  //   return (
  //     <figure className="image is-16by9 mb-3">
  //       <video
  //         width="100%"
  //         height="100%"
  //         controls
  //         muted="muted"
  //         autoplay="autoplay"
  //         loop="loop"
  //       >
  //         <source src={ `/images/nfts/generation-${token.generation}.mp4` } type="video/mp4" />
  //       </video>
  //     </figure>
  //   );
  // }

  // return (
  //   <figure className="image is-16by9 mb-3">
  //     <img src={ `/images/nfts/generation-${token.generation}.png` } alt={ nftName(token) } />
  //   </figure>
  // );
}

export const experienceScale = (experience) => experience.div(100);
