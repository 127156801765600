import React from 'react';
import BigNumber from 'bignumber.js';
import { faCalculator, faSpinner, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { nativeTokenName, stableTokenName } from '../../../../utils/commons';

import FarmHarvestSection from '../FarmHarvestSection';
import FarmHarvestNftSection from '../FarmHarvestNftSection';
import FarmStakeSection from '../FarmStakeSection';
import FarmStakeNftSection from '../FarmStakeNftSection';
import FarmCardFooterSection from './components/FarmCardFooterSection';

import './FarmCard.scss';

const FarmCard = ({ farmData, handleRoiCalculatorModalOpen, handleWithdrawModalOpen, handleDepositModalOpen, nativeTokenPrice, nativeTokenPriceDefault, handleDepositNftModalOpen, handleNftPreviewModalOpen, pendingUsd, pendingExtraBanksy }) => {
  const important = [farmData.token, farmData.quoteToken].includes(process.env.REACT_APP_NATIVE_TOKEN);

  return (
    <>
      <div className="column is-half-tablet is-one-third-desktop is-borderless">
        <div className={ `box is-relative ${important ? 'gradient-border' : ''}` }>
          {farmData.disabled ? (
            <span className="icon" style={{ position: 'absolute', top: 0, right: 0 }}>
              <FontAwesomeIcon icon={ faSpinner } pulse />
            </span>
          ) : null}
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <figure className="image is-64x64">
                  <img src={ `/images/farms/${farmData.name}.png`.replace(' LP', '').replace(' ', '').toLowerCase() } alt={ farmData.name } />
                </figure>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="is-flex is-flex-direction-column is-align-items-flex-end">
                  <h5 className="is-size-5 has-text-weight-semibold">{ farmData.name }</h5>
                  <div>
                    <div className="tags mb-1">
                      <span className="tag is-rounded is-success">
                        { farmData.swap }
                      </span>
                      <span className="tag is-rounded is-warning">
                        { new BigNumber(farmData.allocPoint).div(100).toString() }X
                      </span>
                    </div>
                    {farmData.pid === 0 ? (
                      <span className="tag is-rounded is-info">
                        <span className="icon-text-">
                          <span className="icon">
                            <FontAwesomeIcon icon={ faUsers } />
                          </span>
                          <span>Owner's Pool</span>
                        </span>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                APR
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h6 className="is-size-6 has-text-weight-semibold is-clickable" onClick={ () => handleRoiCalculatorModalOpen(farmData) }>
                  <span className="icon-text">
                    <span className="icon has-text-success">
                      <FontAwesomeIcon icon={ faCalculator } />
                    </span>
                    { new BigNumber(farmData.apr).times(100).toFormat(2) }%
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                EARN
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h6 className="is-size-6 has-text-weight-semibold">
                  { nativeTokenName }
                  {farmData.pid === 0 ? (
                    <span className="ml-1">+ { stableTokenName }</span>
                  ) : null}
                </h6>
              </div>
            </div>
          </div>
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                Deposit Fee
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h6 className="is-size-6 has-text-weight-semibold">{ farmData.depositFeeBP / 100 }%</h6>
              </div>
            </div>
          </div>
          <FarmHarvestSection farmData={ farmData } pendingUsd={ pendingUsd } pendingExtraBanksy={ pendingExtraBanksy } nativeTokenPrice={ nativeTokenPrice } />
          <FarmHarvestNftSection farmData={ farmData } />
          <FarmStakeSection farmData={ farmData } handleWithdrawModalOpen={ handleWithdrawModalOpen } handleDepositModalOpen={ handleDepositModalOpen } nativeTokenPriceDefault={ nativeTokenPriceDefault } />
          <FarmStakeNftSection farmData={ farmData } handleDepositNftModalOpen={ handleDepositNftModalOpen } handleNftPreviewModalOpen={ handleNftPreviewModalOpen } />
          <hr />
          <FarmCardFooterSection farmData={ farmData } nativeTokenPriceDefault={ nativeTokenPriceDefault } />
        </div>
      </div>
    </>
  );
}

export default FarmCard;
